import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { PageContext } from "@util/types";
import { Hero, TabbedContent } from "@components";
import FooterSections from "@shared/footer/footerSections";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

export default function PrivacyPolicyTemplate({ data, pageContext }: Props) {
  if (!data.sanityPrivacyPolicyPage) return null;

  const { pageMeta, policies } = data.sanityPrivacyPolicyPage;

  if (!pageMeta) return null;

  return (
    <>
      <SEO seoData={pageMeta.seo} slug={pageContext.pagePath} />
      <Hero data={pageMeta.pageHero} />
      {policies &&
        <TabbedContent content={policies} />
      }
      <FooterSections pageFooter={pageMeta.pageFooter} />
    </>
  );
}

export const query = graphql`
  query PrivacyPolicyQuery {
    sanityPrivacyPolicyPage {
      pageMeta {
        ...sanityPageMeta
      }
      policies {
        ...sanityTabbedContent
      }
    }
  }
`;
